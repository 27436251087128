import * as actionTypes from '../../store/actionTypes'
import Api from '../../services/api';
const service = new Api();

const initPublicSetting = () => {
	return async (dispatch)  => {
		const { data } = await service.getPublicSetting();
		dispatch({
			type:actionTypes.INIT_SETTING,
			payload: data.settings
		})
	};
}

export {
    initPublicSetting,
}
