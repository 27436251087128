import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Box, CircularProgress, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";
import {useDispatch} from "react-redux";
import Api from "../../../../../../services/api";
import useForm2 from "../../../../../../hooks/useForm2";
import DialogPopUp from "../../../../../DialogPopUp";

const useStyles = makeStyles(theme => ({
            content: {
                width: "300px",
                maxWidth: "100%",
                paddingTop: "28px",
                paddingBottom: "28px",
                marginLeft:"auto",
                marginRight:"auto"
            },
            button: {
                marginTop: '28px'
            }
        }
    )
);


const WalletPaymentMethods = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {setOpen, open, selectedTransaction, setSelectedTransaction, transferringToken, loading} = props;

    const onClose = () => {
        setOpen(false)
    }

    const onMetamaskTransfer = () => {
        return transferringToken(selectedTransaction);
    }

    const onTanganyTransfer = () => {
        history.push({
            pathname: "/balances",
            search:
                "?" +
                new URLSearchParams({
                    expenseId: selectedTransaction.id,
                    wallet: selectedTransaction?.receivingWallet,
                    amount: selectedTransaction.amount,
                    currency:
                    selectedTransaction.currency,
                }).toString(),
        });
    }

    return (
            <DialogPopUp
                open={open}
                onClose={onClose}
                aria-labelledby="reject-title"
                title={t("formFields.reject")}
            >
                <DialogContent className={classes.content}>
                    <FormControl fullWidth>
                        <Grid container>
                            <Grid item xs={12}>
                                {loading ? 
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >   
                                    Please do not close this tab until the transaction is executed.
                                        <CircularProgress />
                                    </Box> : 
                                    <FormControl fullWidth >
                                        Please select your wallet payment method.
                                        <Button className={classes.button} onClick={onTanganyTransfer}>Tangany wallet</Button>
                                        <Button className={classes.button} onClick={onMetamaskTransfer}>Metamask wallet</Button>
                                </FormControl>}

                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary" variant="contained" >
                        {t("formFields.cancel")}
                    </Button>
                </DialogActions>
            </DialogPopUp>
    );
};

export default WalletPaymentMethods;
