import React, { useState, useEffect } from "react";
import axios from "axios";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
    CircularProgress,
    MenuItem,
    makeStyles,
    Grid,
    Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogPopUp from "../../../../DialogPopUp";
import DialogContent from "@material-ui/core/DialogContent";

import { COUNTRY_BY_ALPHA_2_CODE } from "../../../../../constants/country";
import Api from "../../../../../services/api";

import metamaskService from "../../../../../services/metamask";
import InputField from "../../../../formElements/InputField";
// import Icon from '../Icon'
import RadioFields from "../../../../formElements/RadioFields";
import FormControl from "@material-ui/core/FormControl";

import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { setMessage } from "../../../../Messages/actions";
import { TRANSACTION_STATUS } from "../../../../../constants/transactions";
import SelectField from "../../../../formElements/SelectField";

const API_URL =
    window._env_.ENVIRONMENT === "prod"
        ? "https://api.monerium.app"
        : "https://api.monerium.dev";

const volveApi = new Api();

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        padding: "24px 0",
    },
    tableCardWrapper: {
        display: "flex",
        "flex-flow": "row wrap",
        "align-items": "flex-start",
        margin: "0 -24px -32px",
    },
    tableCard: {
        "margin-bottom": "32px",
        "flex-grow": 0,
        "flex-shrink": 0,
        "flex-basis": "calc(50% - 24px)",
        width: "calc(50% - 24px)",
        "margin-left": "12px",
        "margin-right": "12px",
        border: "1px solid #EFEFEF",
        cursor: "pointer",
        transition: "border 250ms ease",
        "border-radius": "4px",
        display: "table",
    },
    row: {
        display: "table-row",
    },
    column: {
        background: "rgba(39, 43, 48, 0.3)",
        width: "120px",
        display: "table-cell",
        padding: "10px 16px",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.71429",
        "& :first-child": {
            "padding-left": "24px",
            "white-space": "nowrap",
            color: "#6F767E",
        },
    },
    label: {
        display: "none",
    },
}));

const ConfirmApprovedTransactionModal = ({
    accounts,
    setCurrentAccount,
    currentAccount,
    isOpenModal = false,
    setIsOpenModal,
    fetchTransactionHistory,
    authToken,
    setMessage,
}) => {
    const { t } = useTranslation();
    const [values, setValues] = useState({
        amount: "",
        memo: "",
        iban: "",
        country: "",
        firstName: "",
        lastName: "",
        sendType: "individual",
    });
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {
        expenseId,
        iban,
        amount,
        firstName,
        lastName,
        country,
        companyName,
        sendType,
    } = queryString.parse(history.location.search);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const getCountryData = async () => {
        const { data } = await volveApi.getServiceData({
            dataFor: "countries",
        });
        if (data?.length) {
            const countryFind = data.find((e) => e["country-code"] === country);
            if (countryFind) {
                setSelectedCountry(countryFind["alpha-2"]);
            }
        }
    };
    useEffect(() => {
        getCountryData();
    }, []);
    useEffect(() => {
        if (expenseId && iban && amount && authToken) {
            setValues({
                ...values,
                amount,
                iban,
                firstName,
                lastName,
                country: selectedCountry,
                companyName,
                sendType,
                memo: `Pay expense ${expenseId}`,
            });
        }

        // eslint-disable-next-line
    }, [authToken, expenseId, iban, amount, selectedCountry]);

    const handleSignMessage = async (message) => {
        if (!metamaskService.getCurrentAddress()) {
            await metamaskService.requestAccounts();
        }

        const account = metamaskService.getCurrentAddress();

        if (account !== currentAccount?.address?.toLowerCase()) {
            setMessage(
                "Please open your blockchain wallet and connect the correct address",
                "error"
            );
            return null;
        }

        let network = currentAccount?.network;

        if (network === "mainnet" && currentAccount?.chain === "polygon") {
            network = "polygon";
        }

        await metamaskService.switchNetwork(network);

        const signature = await metamaskService.signMessage(account, message);
        const signerAddress = await metamaskService.getSigner(
            signature,
            message
        );

        if (signerAddress === currentAccount?.address?.toLowerCase()) {
            return signature;
        } else {
            setMessage(
                "The wallet you provided does not match with the current wallet on Metamask.",
                "error"
            );
            return null;
        }
    };

    const placeOrder = async () => {
        try {
            const message = `Send EUR ${values.amount} to ${
                values.iban
            } at ${moment().format()}`;
            const signature = await handleSignMessage(message);
            if (!signature) {
                return setMessage(
                    "Transferring failed",
                    "error"
                );
            }
            setLoading(true);

            const data = {
                accountId: currentAccount?.id,
                kind: "redeem",
                amount: `${values.amount}`,
                signature,
                message,
                memo: values.memo,
                counterpart: {
                    identifier: {
                        standard: "iban",
                        iban: values.iban,
                    },
                    details:
                        values.sendType === "individual"
                            ? {
                                  firstName: values.firstName,
                                  lastName: values.lastName,
                                  country: values.country,
                              }
                            : {
                                  companyName: values.companyName,
                                  country: values.country,
                              },
                },
            };

            await volveApi.placeMoneriumOrders(data, authToken, expenseId, currentAccount?.address, currentAccount?.currency)

            setMessage(t("Success"), "success");
            setLoading(false);
            setIsOpenModal(false);
            history.push('/transactions')
        } catch (error) {
            console.log(error.response.data);

            if (error.response && error.response.data) {
                setMessage(error.response.data.message, "error");
            }
            setLoading(false);
        }
    };

    return (
        <DialogPopUp
            open={isOpenModal}
            onClose={() => {
                setIsOpenModal(false);
            }}
            aria-labelledby="form-dialog-title"
            title={t("Send money")}
        >
            <DialogContent>
                <div className="popup js-popup animation visible popup__kyc-medium">
                    <div
                        onClick={() => setIsOpenModal(false)}
                        className="popup__overlay js-popup-overlay"
                    >
                        {" "}
                    </div>

                    <div className="popup__wrap js-popup-wrap">
                        <div className="description">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <div className="w-full">
                                        <div className="field__label">
                                            <SelectField
                                                label={t(
                                                    "Current Monerium Account"
                                                )}
                                                name={"currency"}
                                                value={currentAccount}
                                                options={accounts}
                                                onSelectChange={(val) => {
                                                    setCurrentAccount(val);
                                                }}
                                                isLoading={loading}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="w-full">
                                        <div className="field__label">
                                            {currentAccount?.currency?.toUpperCase()}
                                            {": "}
                                            {currentAccount?.amount}
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Amount")}
                                            value={values.amount}
                                            onInputChange={(val) => {
                                                setValues({
                                                    ...values,
                                                    amount: val,
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("Reference / Memo")}
                                            value={values.memo}
                                            onInputChange={(val) => {
                                                setValues({
                                                    ...values,
                                                    memo: val,
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <RadioFields
                                            label="RECIPIENT DETAILS"
                                            options={[
                                                {
                                                    value: "individual",
                                                    label: t("Individual"),
                                                },
                                                {
                                                    value: "company",
                                                    label: t("Company"),
                                                },
                                            ]}
                                            name={"sendType"}
                                            value={values["sendType"]}
                                            onRadioChange={(value) =>
                                                setValues({
                                                    ...values,
                                                    sendType: value,
                                                })
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputField
                                            required
                                            label={t("IBAN")}
                                            value={values.iban}
                                            onInputChange={(val) => {
                                                setValues({
                                                    ...values,
                                                    iban: val,
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <div className="field__label">
                                            {t("Country")}
                                            <span className="text__red">
                                                {" "}
                                                *
                                            </span>
                                        </div>

                                        <div className="field__wrap field__wrap-select">
                                            <TextField
                                                select
                                                value={values.country}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        country: e.target.value,
                                                    });
                                                }}
                                                fullWidth
                                            >
                                                {Object.keys(
                                                    COUNTRY_BY_ALPHA_2_CODE
                                                ).map((country) => (
                                                    <MenuItem
                                                        key={country}
                                                        value={
                                                            COUNTRY_BY_ALPHA_2_CODE[
                                                                country
                                                            ]
                                                        }
                                                    >
                                                        {country}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </FormControl>
                                </Grid>

                                {values.sendType === "individual" && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputField
                                                    required
                                                    label={t("First name")}
                                                    value={values.firstName}
                                                    onInputChange={(val) => {
                                                        setValues({
                                                            ...values,
                                                            firstName: val,
                                                        });
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputField
                                                    required
                                                    label={t("Last name")}
                                                    value={values.lastName}
                                                    onInputChange={(val) => {
                                                        setValues({
                                                            ...values,
                                                            lastName: val,
                                                        });
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}

                                {values.sendType === "company" && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputField
                                                required
                                                label={t("Company name")}
                                                value={values.companyName}
                                                onInputChange={(val) => {
                                                    setValues({
                                                        ...values,
                                                        companyName: val,
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <div className="field">
                                        <button
                                            className="btn button-stroke"
                                            onClick={placeOrder}
                                            disabled={loading}
                                        >
                                            {t("Confirm")}
                                        </button>
                                    </div>
                                </Grid>

                                {loading && (
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                            </Grid>
                        </div>

                        <button
                            className="popup__close js-popup-close"
                            onClick={() => setIsOpenModal(false)}
                        >
                            {/* <Icon
                className={classNames('close', 'icon-24')}
                name="close"
                size="24"
              /> */}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </DialogPopUp>
    );
};

const MoneriumAccount = ({
    accounts,
    authToken,
    currentAccount,
    setCurrentAccount,
    setMessage,
}) => {
    const { t } = useTranslation();
    const [transactionHistory, setTransactionHistory] = useState(null);
    const [loading, setLoading] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const styles = useStyles();
    const history = useHistory();
    const { expenseId, iban, amount } = queryString.parse(
        history.location.search
    );

    const renderExplorerLink = (network, hash) => {
        const shortHash = `${hash.slice(0, 6)}...${hash.slice(
            -4,
            hash.length
        )}`;

        if (hash.includes("https")) {
            return (
                <a href={hash} target="_blank" rel="noopener noreferrer">
                    {hash}
                </a>
            );
        }

        switch (network) {
            case "mainnet":
                return (
                    <a
                        href={`https://etherscan.io/tx/${hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {shortHash}
                    </a>
                );

            case "sepolia":
                return (
                    <a
                        href={`https://sepolia.etherscan.io/tx/${hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {shortHash}
                    </a>
                );

            case "polygon":
                return (
                    <a
                        href={`https://polygonscan.com/tx/${hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {shortHash}
                    </a>
                );

            case "mumbai":
                return (
                    <a
                        href={`https://mumbai.polygonscan.com/tx/${hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {shortHash}
                    </a>
                );

            case "xinfin":
                return (
                    <a
                        href={`https://explorer.xinfin.network/txs/${hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {shortHash}
                    </a>
                );

            case "apothem":
                return (
                    <a
                        href={`https://explorer.apothem.network/txs/${hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {shortHash}
                    </a>
                );

            default:
                return `${hash} (${network})`;
        }
    };

    const fetchTransactionHistory = async () => {
        try {
            const response = await volveApi.getMoneriumTransactionsHistory(currentAccount.id, authToken)

            setTransactionHistory(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (currentAccount && currentAccount?.id) {
            setLoading(true);
            fetchTransactionHistory();
        }
        // eslint-disable-next-line
    }, [currentAccount]);

    useEffect(() => {
        if (expenseId && iban && amount && authToken && setIsOpenModal) {
            setIsOpenModal(true);
        }

        // eslint-disable-next-line
    }, [authToken, expenseId, iban, amount, setIsOpenModal]);

    return (
        <>
            <ConfirmApprovedTransactionModal
                accounts={accounts}
                setCurrentAccount={setCurrentAccount}
                currentAccount={currentAccount}
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                fetchTransactionHistory={fetchTransactionHistory}
                authToken={authToken}
                setMessage={setMessage}
            />

            <div className="page__title page__title-grid h3">
                {currentAccount?.currency?.toUpperCase()}
                {": "}
                {currentAccount?.amount}

                <div className="page__title-helper">
                    <button
                        type="button"
                        className="btn button button-xs"
                        onClick={() => setCurrentAccount(null)}
                    >
                        {/* <Icon
              className={classNames('arrow-left')}
              name='arrow-left'
              size='24'
            />{' '} */}
                        {t("Back")}
                    </button>
                </div>
            </div>

            {loading && (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <CircularProgress />{" "}
                </Box>
            )}

            <div className={styles.card}>
                <div className="card__head">
                    <div className="title-clear card__title">
                        {t("Transaction history")}
                    </div>

                    <button
                        type="button"
                        className="btn button-small button-stroke"
                        onClick={() => setIsOpenModal(true)}
                    >
                        {t("Send")}
                    </button>
                </div>

                <div className={styles.tableCardWrapper}>
                    {transactionHistory &&
                        transactionHistory.map((transaction) => {
                            const { details, identifier } =
                                transaction.counterpart;
                            const isIssued = transaction.kind === "issue";

                            return (
                                <div
                                    className={styles.tableCard}
                                    key={transaction.id}
                                >
                                    <div className={styles.row}> </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {identifier.standard}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {identifier.standard}
                                            </div>
                                            {identifier.iban}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {t("Name")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {t("Name")}
                                            </div>
                                            {details.name}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {t("Reference / Memo")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {t("Reference / Memo")}
                                            </div>
                                            {transaction.memo}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {t("Transaction")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {t("Transaction")}
                                            </div>
                                            {transaction.txHashes[0] &&
                                                renderExplorerLink(
                                                    transaction.network,
                                                    transaction.txHashes[0]
                                                )}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {t("Date and Time")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {t("Date and Time")}
                                            </div>
                                            {transaction.meta.placedAt}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {isIssued
                                                ? t("Amount received")
                                                : t("Amount sent from wallet")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {isIssued
                                                    ? t("Amount received")
                                                    : t(
                                                          "Amount sent from wallet"
                                                      )}
                                            </div>
                                            {transaction.amount}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {t("Monerium fee")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {t("Monerium fee")}
                                            </div>
                                            {transaction.totalFee}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {isIssued
                                                ? t("You get")
                                                : t("Sent to IBAN")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {isIssued
                                                    ? t("You get")
                                                    : t("Sent to IBAN")}
                                            </div>
                                            {transaction.amount -
                                                +(isIssued
                                                    ? transaction.totalFee
                                                    : -transaction.totalFee)}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            {t("Status")}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                {t("Status")}
                                            </div>
                                            {transaction.meta.state ===
                                            "processed"
                                                ? t("Completed")
                                                : transaction.meta.state}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default compose(connect(null, { setMessage }))(MoneriumAccount);
