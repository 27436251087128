import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchAccounts } from "../actions";
// eslint-disable-next-line no-unused-vars
import TopComponentInvesthub from "../TopComponentInvesthub";
import { withRouter } from "react-router-dom";
import FormatMoney from "../../../../FormatMoney";
import checkClick from "../../../../../helpers/checkClick";
import { compose } from "redux";
import moment from "moment-timezone";
import queryString from "query-string";
import Pagination from "../../../../Pagination";
import Portal from "@material-ui/core/Portal";
import { useTranslation } from "react-i18next";
import VolveMaterialTable from "../../../../VolveMaterialTable";
// eslint-disable-next-line no-unused-vars
import HeadContentInvesthub from "../HeadContentInvesthub";
// eslint-disable-next-line no-unused-vars
import ShortInfoItem from "../../../../ShortInfoItem";
import useMounted from "../../../../../hooks/useMounted";
import {
    Box,
    Button,
    makeStyles,
    Tooltip,
    Typography,
} from "@material-ui/core";
import Api from "../../../../../services/api";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { groupBy, filter } from "lodash";
import capitalizeFirstLetter from "../../../../../helpers/capitalizeFirstLetter";
import DialogCreateFiatAccount from "../../Balances/DialogCreateFiatAccount";
import PlusButton from "../../../../PlusButton";
import { ReactComponent as PlusIconSVG } from "../../../../../assets/plusCircleInvesthub.svg";
import { updateUserState } from "../../../../User/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
// eslint-disable-next-line no-unused-vars
import SelectField from "../../../../formElements/SelectField";
import { ACCOUNT_TYPES } from "../../../../../constants/transactions";
import {
    ETH_PRICE_USD_QUERY,
    TOKEN_QUERY,
} from "../../../../../queriesAndMutations/smartContractQueries";
import { useQuery } from "@apollo/client";
import SwapERC20TokenModal from "../SwapERC20TokenModal";
import DialogImportWallet from "./Account/DialogImportWallet";
import { COINS } from "../../../../../constants/currencies-ecurrencies";

const service = new Api();
const API_URL =
    window._env_.ENVIRONMENT === "prod"
        ? "https://api.monerium.app"
        : "https://api.monerium.dev";

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: "24px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "16px",
        },
    },
    head: {
        display: "flex",
        alignItems: "center",
        minHeight: "40px",
        position: "relative",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "24px",
        },
        [theme.breakpoints.down("767")]: {
            flexWrap: "wrap",
            paddingBottom: "24px",
            marginBottom: "24px",
        },
    },
    headTitle: {
        position: "relative",
        display: "inline-block",
        paddingLeft: "32px",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "1.6",
        letterSpacing: "-.02em",
        [theme.breakpoints.down("767")]: {
            fontSize: "18px",
        },
    },
    headControl: {
        display: "flex",
        // marginLeft: "auto",
    },
    headControlAction: {
        marginLeft: "8px",
    },
    headControlButton: {
        padding: "8px 16px",
        borderRadius: "8px",
    },
    overview: {
        display: "block",
        marginBottom: "32px",
        [theme.breakpoints.down("767")]: {
            marginBottom: "24px",
            textAlign: "center",
        },
    },
    items: {
        position: "relative",
    },

    itemWalletAddress: {
        "& $itemLabel": {
            marginRight: "0",
            marginBottom: "8px",
        },
        "& $itemContent": {
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "1.33333",
            color: "var(--battelship-grey)",

            [theme.breakpoints.down("sm")]: {
                maxWidth: "270px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
    },
    itemLabel: {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1.71429",
        color: "var(--charcoal-primary)",
        marginRight: "24px",
        [theme.breakpoints.down("767")]: {
            marginRight: "0",
        },
    },
    itemContent: {
        marginLeft: "auto",
        position: "relative",
        [theme.breakpoints.down("767")]: {
            marginLeft: "0",
        },
    },
    currency: {
        position: "relative",
    },
}));

const AccountsTable = (props) => {
    const classes = useStyle();
    const {
        userAccounts,
        fetchAccounts,
        history,
        underBarRef,
        location,
        userId,
        userData,
        roles,
        settingsDarkMode,
    } = props;
    const {
        accounts: { result, info = {} },
    } = userAccounts;
    const { page, total, pageSize, totalBalance = {} } = info;
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useMounted();
    const { t } = useTranslation();
    const [fiatAccounts, setFiatAccounts] = useState([]);
    const [tanganyWalletBalances, setTanganyWalletBalances] = useState([]);
    const [importedWalletBalances, setImportedWalletBalances] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [companyAccounts, setCompanyAccounts] = useState([]);
    const [balances, setBalances] = useState([]);
    const { search } = location;
    const queryObject = queryString.parse(search);
    const { expenseId, iban, amount } = queryObject;
    const [isCreatingFiatAccount, setIsCreatingFiatAccount] = useState(false);
    const [openModalAddAccount, setOpenModalAddAccount] = useState(false);
    const [openModalImportUserWallet, setOpenModalImportUserWallet] =
        useState(false);
    const [openModalImportCompanyWallet, setOpenModalImportCompanyWallet] =
        useState(false);
    const [openModalAddCompanyAccount, setOpenModalAddCompanyAccount] =
        useState(false);
    const [companyTanganyWalletBalances, setCompanyTanganyWalletBalances] =
        useState([]);
    const [companyFiatAccounts, setCompanyFiatAccounts] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [companyTotalAmount, setCompanyTotalAmount] = useState(0);
    const [companyImportedWalletBalances, setCompanyImportedWalletBalances] =
        useState([]);

    const [canRenderCompany, setCanRenderCompany] = useState(false);
    const [isDisableFiat, setIsDisableFiat] = useState(false);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const fetchData = async () => {
        await fetchAccounts();
        setIsLoading(false);
    };
    // const { data: ethPriceUSD } = useQuery(ETH_PRICE_USD_QUERY);
    const getAuthContext = async (token) => {
        try {
            const response = await service.getMoneriumAuthContext(token);
            return response.data;
        } catch (error) {
            console.log(error);

            return null;
        }
    };
    const { toCurrency, sumInCurrency } = totalBalance;

    const getFiatRepublicListAccount = async () => {
        setIsLoading(true);
        try {
            const host = localStorage?.getItem("host");
            const { data } = await service.getFiatRepublicListAccount(host);
            if (data?.data?.length) {
                setFiatAccounts(
                    data?.data?.map((e) => ({
                        ...e,
                        accountType: ACCOUNT_TYPES.FIAT,
                    }))
                );
            } else {
                setFiatAccounts([]);
            }

            if (data?.msg === t("errors.enableFiat")) {
                setIsDisableFiat(true);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const getCompanyFiatRepublicListAccount = async () => {
        setIsLoading(true);
        try {
            const host = localStorage?.getItem("host");
            const { data } = await service.getCompanyFiatRepublicListAccount(
                host
            );
            if (data?.data?.length) {
                setCompanyFiatAccounts(
                    data.data?.map((e) => ({
                        ...e,
                        accountType: ACCOUNT_TYPES.FIAT,
                    }))
                );
            } else setCompanyFiatAccounts([]);

            if (data?.msg === t("errors.enableFiat")) {
                setIsDisableFiat(true);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const getTanganyWalletBalances = async () => {
        setIsLoading(true);
        try {
            const { data } = await service.getTanganyWalletBalances();
            let wallets = [];
            if (data?.wallets?.length) {
                data.wallets.forEach((wallet) => {
                    wallets.push({
                        currency: wallet.currency,
                        avlBal: wallet?.balance,
                        wallet: wallet.address,
                        walletName: wallet?.walletName,
                        accountType: ACCOUNT_TYPES.TANGANY,
                        network: wallet?.network,
                    });
                    if (wallet?.tokenBalances?.length) {
                        wallet.tokenBalances.forEach((e) => {
                            wallets.push({
                                ...e,
                                avlBal: e.balance,
                                wallet: wallet.address,
                                walletName: wallet?.walletName,
                                accountType: ACCOUNT_TYPES.TANGANY,
                            });
                        });
                    }
                });
                for await (const w of wallets) {
                    const walletBalance = Number(w.avlBal);
                    if (walletBalance) {
                        if (w.currency === "TMATIC" || w.currency === "TETH") {
                            w.currency = w.currency.slice(1, w.currency.length); // Remove the "T" prefix
                        }
                        if (
                            w.currency === "DAI" ||
                            w.currency === "USDC" ||
                            w.currency === "USDT"
                        ) {
                            const response = await service.getExchangeRate(
                                "USD",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency === "EURE") {
                            const response = await service.getExchangeRate(
                                "EUR",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency === "XSGD") {
                            const response = await service.getExchangeRate(
                                "SGD",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency === "XCHF") {
                            const response = await service.getExchangeRate(
                                "CHF",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency && COINS.includes(w.currency)) {
                            const { data: coinPriceResponse } =
                                await service.getCoinPrice(w.currency);
                            const coinPrice =
                                coinPriceResponse?.coinPrice?.data[
                                    w.currency
                                ][0]?.quote?.USD?.price; // MATIC to USD rate
                            if (coinPrice) {
                                // get exchangeData
                                let { data: exchangeData } =
                                    await service.getExchangeRate(
                                        "USD",
                                        toCurrency,
                                        Number(w.avlBal)
                                    );
                                w.baseCurrencyBalance = exchangeData?.result
                                    ? exchangeData?.result * coinPrice
                                    : 0;
                            }
                        }
                    } else {
                        w.baseCurrencyBalance = 0;
                    }
                }
                setTanganyWalletBalances(wallets);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const getImportedWalletBalances = async () => {
        setIsLoading(true);
        try {
            const { data } = await service.getUserImportedWalletBalances();
            if (data?.wallets?.length) {
                let wallets = data?.wallets.flat(Infinity).map((e) => ({
                    ...e,
                    currency: e.currency.toUpperCase(),
                    accountType: ACCOUNT_TYPES.IMPORTED,
                }));
                setImportedWalletBalances(wallets);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const getCompanyImportedWalletBalances = async () => {
        setIsLoading(true);
        try {
            const { data } = await service.getCompanyImportedWalletBalances();

            if (data?.wallets?.length) {
                let wallets = data?.wallets.flat(Infinity).map((e) => ({
                    ...e,
                    currency: e.currency.toUpperCase(),
                    accountType: ACCOUNT_TYPES.IMPORTED,
                }));
                setCompanyImportedWalletBalances(wallets);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const getCompanyTanganyWalletBalances = async () => {
        setIsLoading(true);
        try {
            const { data } = await service.getCompanyTanganyWalletBalances();
            if (data?.wallets?.length) {
                const wallets = [];
                data.wallets.forEach((w) => {
                    if (w?.tokenBalances?.length) {
                        w.tokenBalances.forEach((b) => {
                            wallets.push({
                                wallet: w.address,
                                ...b,
                                avlBal: b.balance,
                                walletName: w.walletName,
                                accountType: ACCOUNT_TYPES.TANGANY,
                                currency: b?.currency?.toUpperCase(),
                            });
                        });
                    }
                    wallets.push({
                        currency: w?.currency.toUpperCase(),
                        avlBal: w?.balance,
                        wallet: w?.address,
                        accountType: ACCOUNT_TYPES.TANGANY,
                        walletName: w.walletName,
                        network: w?.network,
                    });
                });
                for await (const w of wallets) {
                    const walletBalance = Number(w.avlBal);
                    if (walletBalance) {
                        if (w.currency === "TMATIC" || w.currency === "TETH") {
                            w.currency = w.currency.slice(1, w.currency.length); // Remove the "T" prefix
                        }
                        if (
                            w.currency === "DAI" ||
                            w.currency === "USDC" ||
                            w.currency === "USDT"
                        ) {
                            const response = await service.getExchangeRate(
                                "USD",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency === "EURE") {
                            const response = await service.getExchangeRate(
                                "EUR",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency === "XSGD") {
                            const response = await service.getExchangeRate(
                                "SGD",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency === "XCHF") {
                            const response = await service.getExchangeRate(
                                "CHF",
                                toCurrency,
                                Number(w.avlBal)
                            );
                            w.baseCurrencyBalance = response.data.result;
                        } else if (w.currency && COINS.includes(w.currency)) {
                            const { data: coinPriceResponse } =
                                await service.getCoinPrice(w.currency);
                            const coinPrice =
                                coinPriceResponse?.coinPrice?.data[
                                    w.currency
                                ][0]?.quote?.USD?.price; // MATIC to USD rate
                            if (coinPrice) {
                                // get exchangeData
                                let { data: exchangeData } =
                                    await service.getExchangeRate(
                                        "USD",
                                        toCurrency,
                                        Number(w.avlBal)
                                    );
                                w.baseCurrencyBalance = exchangeData?.result
                                    ? exchangeData?.result * coinPrice
                                    : 0;
                            }
                        }
                    } else {
                        w.baseCurrencyBalance = 0;
                    }
                }

                setCompanyTanganyWalletBalances(wallets);
                setIsLoading(false);
            }
        } catch {
            setIsLoading(false);
        }
    };
    const getProfileBalances = async (profile, token) => {
        try {
            const response = await service.getMoneriumProfileBalances(
                profile,
                token
            );
            const balanceList = [];
            if (response?.data?.length) {
                response.data.forEach((e) => {
                    if (e?.balances?.length) {
                        e.balances.forEach((b) => {
                            balanceList.push({
                                wallet: e.address,
                                currency: b.currency.toUpperCase(),
                                avlBal: parseFloat(b.amount),
                                label:
                                    capitalizeFirstLetter(e.chain) +
                                    " - " +
                                    capitalizeFirstLetter(e.network),
                                accountType: ACCOUNT_TYPES.MONERIUM,
                            });
                        });
                    }
                });
            }
            setBalances(balanceList);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        getFiatRepublicListAccount();
        if (toCurrency) {
            getTanganyWalletBalances();
        }
        getImportedWalletBalances();
    }, [toCurrency]);

    useEffect(() => {
        if (canRenderCompany) {
            const getAppData = async () => {
                const { data } = await service.getMoneriumAuthToken();
                if (!expenseId) {
                    const profile = await getAuthContext(
                        data.data.access_token
                    );

                    if (profile) {
                        await getProfileBalances(
                            profile,
                            data.data.access_token
                        );
                    }
                }
            };
            if (toCurrency) {
                getCompanyTanganyWalletBalances();
            }
            getCompanyFiatRepublicListAccount();
            getCompanyImportedWalletBalances();
            getAppData();
        }
    }, [canRenderCompany, toCurrency]);

    useEffect(() => {
        if (!(roles?.length === 1 && roles[0] === "personal")) {
            setCanRenderCompany(true);
        }
    }, [roles]);
    const handleChangePage = async (event, newPage) => {
        setIsLoading(true);
        await fetchAccounts({
            page: newPage,
        });
        setIsLoading(false);
    };

    const rowHandlerClick = (e, rowData) => {
        const monthStart = moment.utc().startOf("month").format();
        const wasClicked = checkClick();
        const type = rowData?.accountType;
        let query = "";
        let pathname = `${props.location.pathname}/${type}/${rowData.id}/details${query}`;

        switch (type) {
            case ACCOUNT_TYPES.MONERIUM:
                query = `?token=${rowData?.currency}`;
                pathname = `${props.location.pathname}/${type}/${rowData?.wallet}/details${query}`;
                break;
            case ACCOUNT_TYPES.FIAT:
                break;
            case ACCOUNT_TYPES.TANGANY:
                query = `?token=${rowData?.currency}`;
                if (rowData?.network?.includes("Polygon")) {
                    query = query + `&network=polygon`;
                } else if (rowData?.network?.includes("Ethereum")) {
                    query = query + `&network=ethereum`;
                }
                pathname = `${props.location.pathname}/${type}/${rowData?.walletName}/details${query}`;
                break;
            case ACCOUNT_TYPES.IMPORTED:
                query = `?token=${rowData?.currency}`;
                if (rowData?.network?.toLowerCase() === "polygon") {
                    query = query + `&network=polygon`;
                } else if (rowData?.network?.toLowerCase() === "ethereum") {
                    query = query + `&network=ethereum`;
                }
                pathname = `${props.location.pathname}/${type}/${rowData?.wallet}/details${query}`;
                break;

            default:
                pathname = `${props.location.pathname}/${type}/${rowData.id}/details${query}`;
                break;
        }

        if (wasClicked) {
            history.push(pathname);
        }
    };

    const columns = [
        {
            title: t("Label"),
            field: "label",
            render: (row) => {
                if (row?.accountType !== ACCOUNT_TYPES.IMPORTED) {
                    return row?.label
                        ? row.label
                        : row?.network
                        ? row?.network
                        : "-";
                } else return row?.walletName + " - " + row?.network;
            },
        },
        {
            title: t("thead.currency"),
            field: "curCode",
            render: (row) => row.curCode || row.currency,
        },
        {
            title: t("thead.avlBal"),
            field: "avlBal",
            align: "right",
            render: (row) => {
                return (
                    <FormatMoney>
                        {row?.avlBal || row?.balance?.actual}
                    </FormatMoney>
                );
            },
        },
        {
            title: t("thead.linkedCards"),
            field: "card",
            align: "right",
            render: ({ card }) =>
                card && card.linkedCards ? card && card.linkedCards.length : 0,
        },
        {
            title: t("IBAN"),
            field: "iban",
            render: (row) => {
                const { bankDetails } = row;
                return bankDetails && bankDetails?.iban
                    ? bankDetails.iban
                    : "-";
            },
        },
        {
            title: "Wallet address",
            field: "wallet",
            render: (row) => {
                return row?.wallet ? row?.wallet : "-";
            },
        },
    ];

    useEffect(() => {
        setAccounts([
            ...result,
            ...fiatAccounts,
            ...tanganyWalletBalances,
            ...importedWalletBalances,
        ]);
        let total = 0;
        const allPersonalAccounts = [
            ...result,
            ...fiatAccounts,
            ...tanganyWalletBalances,
            ...importedWalletBalances,
        ];
        allPersonalAccounts.forEach((e) => (total += +e.baseCurrencyBalance || +e?.avlBal || +e?.balance?.actual || 0));
        setTotalAmount(total);
    }, [result, fiatAccounts, tanganyWalletBalances, importedWalletBalances]);

    useEffect(() => {
        setCompanyAccounts([
            ...companyFiatAccounts,
            ...companyTanganyWalletBalances,
            ...balances,
            ...companyImportedWalletBalances,
        ]);
        let total = 0;
        const allCompanyAccounts = [
            ...companyFiatAccounts,
            ...companyTanganyWalletBalances,
            ...balances,
            ...companyImportedWalletBalances,
        ];
        allCompanyAccounts.forEach((e) => {
            total +=
                +e.baseCurrencyBalance || +e.avlBal || +e?.balance?.actual || 0;
        });
        setCompanyTotalAmount(total);
    }, [
        companyTanganyWalletBalances,
        companyFiatAccounts,
        balances,
        companyImportedWalletBalances,
    ]);

    const requestCreateNewWallet = async () => {
        setIsLoading(true);

        try {
            await service.generateWallet();
            const userResult = await service.getCurrentUser();
            const { data } = userResult;
            updateUserState(data);
            await getTanganyWalletBalances();

            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const requestCreateNewCompanyWallet = async () => {
        setIsLoading(true);

        try {
            await service.generateCompanyWallet();
            await getCompanyTanganyWalletBalances();
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <Typography
                        variant="h1"
                        className={`${classes.pageTitle} h3`}
                    >
                        {t("account.mainTitle")}
                    </Typography>
                </Portal>
            )}
            <Box className={`card `}>
                <Button onClick={() => setIsOpenCreateModal(true)}>
                    Swap Or Buy Token
                </Button>
            </Box>
            <SwapERC20TokenModal
                isOpenCreateModal={isOpenCreateModal}
                setIsOpenCreateModal={setIsOpenCreateModal}
                settingsDarkMode={settingsDarkMode}
            ></SwapERC20TokenModal>
            <Box className={`card `}>
                <Box className={classes.head}>
                    <Box
                        className={`${classes.headTitle}  card-title card-title--blue`}
                    >
                        {/* NEED TRANSLATION */}
                        {t("Personal")}
                    </Box>
                    <Box className={`${classes.headControl}`}>
                        <Tooltip title={t("Import User Wallet")}>
                            <PlusButton
                                onClick={() =>
                                    setOpenModalImportUserWallet(true)
                                }
                                settingsDarkMode={settingsDarkMode}
                            >
                                <PlusIconSVG />
                            </PlusButton>
                        </Tooltip>
                    </Box>
                    <Box className={`${classes.headControl}`}>
                        {/* NEED TRANSLATION */}
                        <Tooltip title={t("Create Fiat Account")}>
                            <PlusButton
                                onClick={() => setOpenModalAddAccount(true)}
                                settingsDarkMode={settingsDarkMode}
                            >
                                <PlusIconSVG />
                            </PlusButton>
                        </Tooltip>
                        {!userData.walletAddress && (
                            <div
                                className={`${classes.item} ${classes.itemLabelCenter}`}
                            >
                                <div className={classes.itemContent}>
                                    {isLoading && (
                                        <Box
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}

                                    {!isLoading && (
                                        <Button
                                            className={`btn-small btn-stroke`}
                                            onClick={requestCreateNewWallet}
                                        >
                                            {/* NEED TRANSLATION */}
                                            {t("Generate one")}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </Box>
                </Box>

                <Box className={classes.overview}>
                    <Box className={`h4 ${classes.overviewTitle}`}>
                        {t("account.totalAmount")}{" "}
                        {isLoading ? (
                            "..."
                        ) : (
                            <>
                                {toCurrency}{" "}
                                <FormatMoney>{totalAmount}</FormatMoney>
                            </>
                        )}
                    </Box>
                </Box>

                <Box className="table-wrap table-wrap--have-hover table-wrap--have-line table-wrap--have-mx-offset">
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={accounts}
                        onRowClick={rowHandlerClick}
                    />
                    {isDisableFiat && <>{t("errors.enableFiat")}</>}
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </Box>

                {openModalAddAccount && (
                    <DialogCreateFiatAccount
                        setOpen={setOpenModalAddAccount}
                        loading={isCreatingFiatAccount}
                        title={t("Create Fiat Account")}
                        getFiatRepublicListAccount={getFiatRepublicListAccount}
                        type="user"
                        settingsDarkMode={settingsDarkMode}
                    />
                )}
                {openModalImportUserWallet && (
                    <DialogImportWallet
                        setOpen={setOpenModalImportUserWallet}
                        // loading={isCreatingFiatAccount}
                        title={t("Import User Wallet")}
                        getFiatRepublicListAccount={getFiatRepublicListAccount}
                        type="user"
                        settingsDarkMode={settingsDarkMode}
                    />
                )}

                {/* IBAN select */}
                {/* <div>Select your paid IBAN</div>
                <SelectField
                    placeholder={t("Select your paid IBAN")}
                    name={"iban"}
                    options={[{label: 'a', value: 'a'}]}
                    // onSelectChange={(val) => handleChange("currency", val)}
                /> */}
            </Box>
            {canRenderCompany && (
                <Box className={`card`}>
                    <Box className={classes.head}>
                        <Box
                            className={`${classes.headTitle}  card-title card-title--green`}
                        >
                            {/* NEED TRANSLATION */}
                            {t("Company")}
                        </Box>
                        <div
                            className={`${classes.item} ${classes.itemLabelCenter}`}
                        >
                            <Tooltip title={t("Import Company Wallet")}>
                                <PlusButton
                                    onClick={() =>
                                        setOpenModalImportCompanyWallet(true)
                                    }
                                    settingsDarkMode={settingsDarkMode}
                                >
                                    <PlusIconSVG />
                                </PlusButton>
                            </Tooltip>
                        </div>

                        <Box className={`${classes.headControl}`}>
                            {/* NEED TRANSLATION */}
                            <Tooltip title={t("Create Fiat Account")}>
                                <PlusButton
                                    onClick={() =>
                                        setOpenModalAddCompanyAccount(true)
                                    }
                                    customClass={classes.customClass}
                                    settingsDarkMode={settingsDarkMode}
                                >
                                    <PlusIconSVG />
                                </PlusButton>
                            </Tooltip>
                            <div className={classes.headControlAction}>
                                {isLoading && (
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}

                                {!isLoading && (
                                    <Button
                                        className={`btn-small btn-stroke ${classes.headControlButton}`}
                                        onClick={requestCreateNewCompanyWallet}
                                    >
                                        {/* NEED TRANSLATION */}
                                        {t("Generate Company Wallet")}
                                    </Button>
                                )}
                            </div>
                        </Box>
                    </Box>

                    <Box className={classes.overview}>
                        <Box className={`h4 ${classes.overviewTitle}`}>
                            {t("account.totalAmount")}{" "}
                            {isLoading ? (
                                "..."
                            ) : (
                                <>
                                    {toCurrency}{" "}
                                    <FormatMoney>
                                        {companyTotalAmount}
                                    </FormatMoney>
                                </>
                            )}
                        </Box>
                    </Box>

                    <Box className="table-wrap table-wrap--have-hover table-wrap--have-line table-wrap--have-mx-offset">
                        <VolveMaterialTable
                            columns={columns}
                            isLoading={isLoading}
                            data={companyAccounts}
                            onRowClick={rowHandlerClick}
                        />
                        {isDisableFiat && <>{t("errors.enableFiat")}</>}
                        <Pagination
                            total={total}
                            pageSize={pageSize}
                            page={page}
                            isLoading={isLoading}
                            onChangePage={handleChangePage}
                            loadMore
                        />
                    </Box>

                    {openModalAddCompanyAccount && (
                        <DialogCreateFiatAccount
                            setOpen={setOpenModalAddCompanyAccount}
                            loading={isCreatingFiatAccount}
                            title={t("Create Fiat Account")}
                            getFiatRepublicListAccount={
                                getCompanyFiatRepublicListAccount
                            }
                            type="company"
                            settingsDarkMode={settingsDarkMode}
                        />
                    )}
                    {openModalImportCompanyWallet && (
                        <DialogImportWallet
                            setOpen={setOpenModalImportCompanyWallet}
                            // loading={isCreatingFiatAccount}
                            title={t("Import Company Wallet")}
                            getFiatRepublicListAccount={
                                getFiatRepublicListAccount
                            }
                            type="company"
                            settingsDarkMode={settingsDarkMode}
                        />
                    )}
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { userData } = user;
    const { regional = {} } = user.settings;
    const { dateFormat } = regional;

    return {
        userAccounts: state.userAccounts,
        isAuthenticated: user.isAuthenticated,
        dateFormat,
        userData,
        roles: user.roles,
    };
};

export default compose(
    connect(mapStateToProps, { fetchAccounts }),
    withRouter
)(AccountsTable);
